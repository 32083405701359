import React from 'react';
import { FiGithub } from 'react-icons/fi';
import { FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrap">
          <h3 className="footer__copyrights">Copyright © 2024. All rights are reserved</h3>
          <div className="footer__links">
            {/* <a
              href="https://www.linkedin.com/in/nikita-pankov/"
              target="_blank"
              className="footer__link">
              <FaLinkedin />
            </a> */}
            <a href="https://github.com/nik191010" target="_blank" className="footer__link">
              <FiGithub />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
