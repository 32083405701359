import React from 'react';
import { RxHamburgerMenu, RxCross1 } from 'react-icons/rx';
import clsx from 'clsx';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const menuSwitcher = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <header className="header">
        <div className="header__l">
          <h3 className="header__logo">Nikita.dev</h3>
        </div>
        <div className="header__r">
          <ul className="header__menu">
            <li className="header__menu-item">
              <AnchorLink offset={() => 220} className="header__menu-link" href="#home">
                Home
              </AnchorLink>
            </li>
            <li className="header__menu-item">
              <AnchorLink offset={() => 80} className="header__menu-link" href="#about">
                About
              </AnchorLink>
            </li>
            <li className="header__menu-item">
              <AnchorLink offset={() => 80} className="header__menu-link" href="#projects">
                Projects
              </AnchorLink>
            </li>
            <li className="header__menu-item">
              <AnchorLink offset={() => 80} className="header__menu-link" href="#contact">
                Contact
              </AnchorLink>
            </li>
          </ul>
          <a href="#" onClick={menuSwitcher} className="header__menu-burger">
            <RxHamburgerMenu className="header__menu-icon" />
          </a>
        </div>
      </header>
      <div className={clsx('mobile-menu', { 'mobile-menu_open': open })}>
        <a href="#" onClick={menuSwitcher} className="mobile-menu__close">
          <RxCross1 className="mobile-menu__icon" />
        </a>
        <ul className="mobile-menu__list">
          <li className="mobile-menu__item">
            <AnchorLink
              onClick={menuSwitcher}
              offset={() => 220}
              className="mobile-menu__link"
              href="#home">
              Home
            </AnchorLink>
          </li>
          <li className="mobile-menu__item">
            <AnchorLink
              onClick={menuSwitcher}
              offset={() => 80}
              className="mobile-menu__link"
              href="#about">
              About
            </AnchorLink>
          </li>
          <li className="mobile-menu__item">
            <AnchorLink
              onClick={menuSwitcher}
              offset={() => 80}
              className="mobile-menu__link"
              href="#projects">
              Projects
            </AnchorLink>
          </li>
          <li className="mobile-menu__item">
            <AnchorLink
              onClick={menuSwitcher}
              offset={() => 80}
              className="mobile-menu__link"
              href="#contact">
              Contact
            </AnchorLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
